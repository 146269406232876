import { useEffect } from "react";
import {
  DefaultValue,
  atom,
  selector,
  useRecoilCallback,
  useRecoilValue,
} from "recoil";
import { ApiService, type ProductServiceSearchResponse } from "../generated";
import { handleError } from "../utils/generatedApi";
import { isAuthenticatedState } from "./user";

const productInitializedState = atom<boolean>({
  key: "productInitializedState",
  default: false,
});

const productLoadingState = atom<boolean>({
  key: "productLoadingState",
  default: false,
});

export const productLastSearchQueryState = atom<string>({
  key: "productLastSearchQueryState",
  default: "",
});

const DEFAULT_PRODUCT_RESPONSE = {
  hits: [],
  count: 0,
  totalCount: 0,
  pageSize: 6,
  page: 0,
};

const initialProductResponseState = atom<ProductServiceSearchResponse>({
  key: "initialProductResponseState",
  default: DEFAULT_PRODUCT_RESPONSE,
});

const productResponseState = atom<ProductServiceSearchResponse>({
  key: "productResponseState",
  default: DEFAULT_PRODUCT_RESPONSE,
});

export const productState = selector({
  key: "productState",
  get: ({ get }) => {
    return {
      isInitialized: get(productInitializedState),
      isLoading: get(productLoadingState),
      initialProducts: get(initialProductResponseState),
      products: get(productResponseState),
      lastSearchQuery: get(productLastSearchQueryState),
    };
  },
  set: ({ set }, value) => {
    if (value instanceof DefaultValue) return;

    set(productInitializedState, value.isInitialized);
    set(productLoadingState, value.isLoading);
    set(productResponseState, value.products);
    set(productLastSearchQueryState, value.lastSearchQuery);
  },
});

export function matchesSupplier(
  query: string,
  supplierHandle: string,
  supplierName: string
) {
  const lowerCaseQuery = query.toLowerCase();
  return (
    lowerCaseQuery.includes(supplierHandle.toLowerCase()) ||
    lowerCaseQuery.includes(supplierName.toLowerCase())
  );
}

export function useSearchProductServicesCallback() {
  return useRecoilCallback(
    ({ set }) =>
      async (supplierHandle: string, query: string, page: number) => {
        try {
          set(productLoadingState, true);
          const response = await ApiService.apiV1ProductServicesRetrieve(
            supplierHandle,
            page,
            query
          );
          set(productResponseState, response);
          set(productLastSearchQueryState, query);
          set(productLoadingState, false);
          return response;
        } catch (error) {
          handleError(error);
          set(productResponseState, { ...DEFAULT_PRODUCT_RESPONSE });
          set(productLoadingState, false);
        }
      },
    []
  );
}

function useInitializeProductServicesCallback() {
  const search = useSearchProductServicesCallback();

  return useRecoilCallback(
    ({ snapshot, set }) =>
      async (supplierHandle: string, query: string) => {
        const product = await snapshot.getPromise(productState);
        if (product.isInitialized) return;

        const response = await search(supplierHandle, query, 0);
        if (!response?.count) {
          await search(supplierHandle, "", 0);
        } else {
          set(initialProductResponseState, response);
        }
        set(productInitializedState, true);
      },
    []
  );
}

export default function useInitializeProductServices({
  hasProducts,
  handle,
  name,
  query,
}: {
  hasProducts: boolean;
  handle: string;
  name: string;
  query: string;
}) {
  const initializeProductServices = useInitializeProductServicesCallback();
  const isAuthenticated = useRecoilValue(isAuthenticatedState);

  useEffect(() => {
    if (!isAuthenticated || !hasProducts) return;

    const includesSupplierName = matchesSupplier(query, handle, name);
    initializeProductServices(handle, includesSupplierName ? "" : query);
  }, [
    isAuthenticated,
    hasProducts,
    handle,
    name,
    query,
    initializeProductServices,
  ]);
}
