import { atom } from "recoil";

import type { BaseSupplier, SupplierAgreementData } from "../generated";
import { getLocalStorageEffect } from "./util";

export interface MessageSupplierData {
  contact?: { fullName?: string; title?: Maybe<string> };
  supplier: BaseSupplier;
  supplierAgreement: SupplierAgreementData;
  contractId?: string;
  contractIsPro?: boolean;
  saved?: boolean;
}

export enum MessageSupplierRecommendationTypes {
  SIMILAR_CONTRACTS = "SIMILAR_CONTRACTS",
  SAME_SOLICITATION = "SAME_SOLICITATION",
  SIMILAR_OFFERINGS = "SIMLIAR_OFFERINGS",
}

export const headingsByMessageSuggestionType = {
  [MessageSupplierRecommendationTypes.SIMILAR_CONTRACTS]:
    "Message suppliers who have similar contracts",
  [MessageSupplierRecommendationTypes.SAME_SOLICITATION]:
    "Message more awarded suppliers from this solicitation",
  [MessageSupplierRecommendationTypes.SIMILAR_OFFERINGS]:
    "Message suppliers who have contracts with similar offerings",
};

interface SharedMessageSupplierProps {
  ccEmails: string[];
  /**
   * Source of the recommendation route most recently used to message suppliers.
   */
  recommendationTypeLastUsed?: MessageSupplierRecommendationTypes;
  /**
   * Suppliers that the user messaged most recently for this contract. Used for:
   * 1. success page copy (`we messaged ${supplier} and ${messagedSuppliers.length} others`
   * 2. pass to getSolicitationOthers
   */
  messagedSuppliers: MessageSupplierData[];

  /**
   * The suppliers/solicitations we showed on the message success page.
   * Populates the "add more suppliers" modal on the post-success message page.
   */
  recommendedSuppliers: MessageSupplierData[];
  /**
   * Selected from our recommendations on the message success page.
   * Populates the initial SupplierChipInput on the post-success message page.
   */
  recommendedSuppliersToMessage: MessageSupplierData[];
}

interface MessageSupplierFromSupplierProps extends SharedMessageSupplierProps {
  rootSupplierHandle: string;
}

interface MessageSupplierFromContractProps extends SharedMessageSupplierProps {
  rootDocid: string;
  /**
   * Used for initialList in getSolicitationOthers
   */
  selectedContractIds: string[];
}

// The key should be the root doc id or root supplier handle
type messageSupplierStateType = Record<
  string,
  MessageSupplierFromSupplierProps | MessageSupplierFromContractProps
>;

export const messageSupplierState = atom<messageSupplierStateType>({
  key: "messageSupplierState",
  default: {},
  effects: [getLocalStorageEffect("messageSupplierState", true)],
});
