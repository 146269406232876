import listify from "listify";
import { useRef, useState } from "react";
import type { BaseSupplier, ProductService } from "../../generated";
import { BoldedText, Link, Typography } from "../../library";
import BookmarkLink from "../../library/BookmarkLink";
import { ChipList } from "../../library/ChipList/ChipList";
import { matchesSupplier } from "../../recoil/products";
import type { Bookmark, OCRFile } from "../../shared/types";
import { bgColorClass } from "../../utils/colors";
import { trackScopeSummaryBookmarkClick } from "../../utils/tracking";
import { SlimProductSection } from "../ContractSearch/ProductSection";

function OfferingsSummary({
  offerings,
  summary,
  query,
}: { offerings: string[]; summary: Maybe<string>; query?: string }) {
  const ref = useRef(null);
  const [showDetails, setShowDetails] = useState(false);
  const getText = () => {
    if (summary) return summary;
    if (!offerings.length) return null;

    let offeringsText = listify(
      offerings.slice(0, 3).map((v) => `"${v}"`),
      {
        finalWord: offerings.length <= 3 ? "and" : "",
      }
    );

    if (offerings.length > 3) offeringsText += ", and more";

    return `This contract offers ${offeringsText}.`;
  };

  const text = getText();
  if (!text) return null;

  return (
    <div ref={ref} className="grid gap-2 justify-items-start">
      <BoldedText
        highlight={query || ""}
        text={text}
        highlightColor={bgColorClass.accent.persimmon.enabled}
        textColor="neutral.boldest.enabled"
        includeSingular
      />
      {showDetails && (
        <ChipList parentRef={ref} keywords={offerings} query={query} />
      )}
      {!!offerings.length && (
        <Link
          className="analytis-scope-summary-offerings-toggle hover:underline"
          underline={false}
          onClick={() => setShowDetails(!showDetails)}
        >
          {showDetails ? "See less" : "See all contract offerings"}
        </Link>
      )}
    </div>
  );
}

function KeywordsSummary({
  keywords,
  supplierName,
  query,
}: { keywords: string[]; supplierName: string; query?: string }) {
  const ref = useRef(null);
  const [showDetails, setShowDetails] = useState(false);
  const getText = () => {
    if (!keywords.length) return null;

    let keywordsText = listify(
      keywords.slice(0, 3).map((v) => `"${v}"`),
      {
        finalWord: keywords.length <= 3 ? "and" : "",
      }
    );

    if (keywords.length > 3) keywordsText += ", and more";

    return `${supplierName} offers ${keywordsText}.`;
  };

  const text = getText();
  if (!text) return null;

  return (
    <div ref={ref} className="grid gap-2 justify-items-start">
      <BoldedText
        highlight={query || ""}
        text={text}
        highlightColor={bgColorClass.accent.persimmon.enabled}
        textColor="neutral.boldest.enabled"
        includeSingular
      />
      {showDetails && (
        <ChipList parentRef={ref} keywords={keywords} query={query} />
      )}
      {!!keywords.length && (
        <Link
          className="analytis-scope-summary-keywords-toggle hover:underline"
          underline={false}
          onClick={() => setShowDetails(!showDetails)}
        >
          {showDetails ? "See less" : "See all supplier offerings"}
        </Link>
      )}
    </div>
  );
}

export interface FileBookmark {
  fileWithBookmark: OCRFile;
  bookmark: Bookmark;
}

interface SimplifiedScopeSummaryProps {
  scrollToViewer: () => void;
  handleProductListClick: () => void;
  handleDocumentsClick: () => void;
  handleBookmarkClick: (fileBookmark: FileBookmark) => void;
  fileBookmark: FileBookmark | null;
  showProductList?: boolean;
  supplier: BaseSupplier;
  offerings: string[];
  summary: Maybe<string>;
  products: ProductService[];
  keywords: string[];
  query?: string;
}

export default function SimplifiedScopeSummary({
  showProductList,
  handleProductListClick,
  handleDocumentsClick,
  handleBookmarkClick,
  fileBookmark,
  supplier,
  offerings,
  summary,
  products,
  keywords,
  query,
}: SimplifiedScopeSummaryProps) {
  const bookmarkType =
    fileBookmark?.bookmark.bookmark_type || "contract documents";
  const showProductSummary =
    showProductList &&
    query &&
    !matchesSupplier(query, supplier.handle, supplier.displayName);

  return (
    <div className="grid gap-4 h-fit">
      <div className="flex flex-row justify-between">
        <Typography
          variant="headline"
          size="sm"
          emphasis
          color="brand.boldest.enabled"
        >
          Scope summary
        </Typography>
        <div className="flex flex-row gap-4">
          {showProductList && (
            <BookmarkLink
              onClick={() => {
                trackScopeSummaryBookmarkClick({
                  bookmarkType: "supplier products",
                });
                handleProductListClick();
              }}
              label="Jump to products"
              background
            />
          )}
          <BookmarkLink
            onClick={() => {
              trackScopeSummaryBookmarkClick({
                bookmarkType,
              });
              if (fileBookmark) handleBookmarkClick(fileBookmark);
              else handleDocumentsClick();
            }}
            label={`Jump to ${fileBookmark ? bookmarkType.toLowerCase() : "docs"}`}
            background
          />
        </div>
      </div>
      <OfferingsSummary offerings={offerings} summary={summary} query={query} />
      <KeywordsSummary
        keywords={keywords}
        supplierName={supplier.displayName}
        query={query}
      />
      {showProductSummary && (
        <SlimProductSection
          products={products}
          onClick={handleProductListClick}
        />
      )}
    </div>
  );
}
