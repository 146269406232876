import clsx from "clsx";

interface ImageWithFallbackProps {
  className?: string;
  src: string;
  alt: string;
  title?: string;
  border?: boolean;
  srcFallback: string;
  fallbackClassName?: string;
  // Valid values of https://tailwindcss.com/docs/object-fit
  fit?:
    | "object-cover"
    | "object-contain"
    | "object-fill"
    | "object-scale-down"
    | "object-none";
}

export default function ImageWithFallback({
  className,
  src,
  alt,
  title,
  border = true,
  srcFallback,
  fallbackClassName,
  fit = "object-cover",
}: ImageWithFallbackProps) {
  return (
    <object
      className={clsx(fit, className, { "border rounded-xl": border })}
      type="image/png"
      data={src}
      aria-label={alt}
      title={title}
    >
      <img
        className={clsx(className, fallbackClassName)}
        src={srcFallback}
        alt={alt}
      />
    </object>
  );
}
