import clsx from "clsx";
import { singular } from "pluralize";
import { useMemo, useRef } from "react";
import { useRecoilValue, useSetRecoilState } from "recoil";
import imageIcon from "../../../img/icons/image-filled.svg";
import VerifiedDocIcon from "../../../img/icons/verified-doc.svg";
import {
  type BaseSupplier,
  ContractScopeTypeEnum,
  type ProductService,
  type ProductServiceVariant,
} from "../../generated";
import { BoldedText, ClampedText, Typography } from "../../library";
import BookmarkLink from "../../library/BookmarkLink";
import { ChipList } from "../../library/ChipList/ChipList";
import ImageWithFallback from "../../library/ImageWithFallback";
import { withTooltip } from "../../library/Tooltip";
import type { ContractSolicitationDetails } from "../../pages/Contract";
import {
  currentFileIndexState,
  selectedBookmarkIdState,
} from "../../recoil/files";
import { matchesSupplier, productState } from "../../recoil/products";
import { contractScopeState } from "../../recoil/scope";
import { searchQueryState } from "../../recoil/search";
import {
  getSentenceCase,
  sortFilesByType,
  sortStringsWithQuery,
} from "../../utils";
import { bgColorClass, borderColorClass } from "../../utils/colors";
import { isFeatureEnabled } from "../../utils/split";
import {
  trackScopeSummaryBookmarkClick,
  trackScopeSummaryProductCardClick,
  trackScopeSummaryShowMoreClick,
} from "../../utils/tracking";
import { getFileIndex } from "./ContractFiles/utils";
import ContractOfferings from "./ContractOfferings";
import ScopeSectionHeader from "./ScopeSectionHeader";
import SimplifiedScopeSummary, {
  type FileBookmark,
} from "./SimplifiedScopeSummary";

export default function ScopeSummary({
  contractDetails,
  scrollToViewer,
  handleProductListClick,
  handleDocumentsClick,
  showProductList,
}: {
  contractDetails: ContractSolicitationDetails;
  scrollToViewer: () => void;
  handleProductListClick: () => void;
  handleDocumentsClick: () => void;
  showProductList?: boolean;
}) {
  const sortedFiles = useMemo(
    () => sortFilesByType(contractDetails.file_information),
    [contractDetails.file_information]
  );
  const setCurrentFileIndex = useSetRecoilState(currentFileIndexState);
  const setSelectedBookmarkId = useSetRecoilState(selectedBookmarkIdState);
  const query = useRecoilValue(searchQueryState);
  const { scope } = useRecoilValue(contractScopeState);
  const { initialProducts } = useRecoilValue(productState);

  const sortedKeywords = useMemo(() => {
    if (!contractDetails.supplier_keywords?.length) return [];

    if (scope.keywords.length) {
      return scope.keywords.map(({ keyword }) => keyword).map(getSentenceCase);
    }

    const capitalizedKeywords =
      contractDetails.supplier_keywords.map(getSentenceCase);
    return sortStringsWithQuery(capitalizedKeywords, query);
  }, [contractDetails, query, scope.keywords]);
  // Iterate through all files and create an array of unique bookmark_types
  const uniqueBookmarks = sortedFiles.reduce((acc: FileBookmark[], file) => {
    file.bookmarks.forEach((bookmark) => {
      const existingBookmarkWithType = acc.find(
        (b) => b.bookmark.bookmark_type === bookmark.bookmark_type
      );
      if (!existingBookmarkWithType) {
        acc.push({ fileWithBookmark: file, bookmark });
      }
    });
    return acc;
  }, [] as FileBookmark[]);

  const handleBookmarkClick = ({
    fileWithBookmark,
    bookmark,
  }: FileBookmark) => {
    const fileIndex = getFileIndex(sortedFiles, fileWithBookmark.id);
    if (fileIndex === -1) return;
    setCurrentFileIndex(fileIndex);
    // Use the first bookmark available
    setSelectedBookmarkId(bookmark.id);
    scrollToViewer();
  };

  if (isFeatureEnabled("simplifiedContractPage")) {
    return (
      <SimplifiedScopeSummary
        scrollToViewer={scrollToViewer}
        handleProductListClick={handleProductListClick}
        handleDocumentsClick={handleDocumentsClick}
        handleBookmarkClick={handleBookmarkClick}
        fileBookmark={uniqueBookmarks.length ? uniqueBookmarks[0] : null}
        showProductList={showProductList}
        supplier={contractDetails.supplier}
        offerings={contractDetails.offerings}
        summary={contractDetails.summary}
        products={initialProducts.hits}
        keywords={sortedKeywords}
        query={query}
      />
    );
  }

  return (
    <>
      <Typography
        emphasis
        size="sm"
        variant="headline"
        color="brand.default.secondary.enabled"
      >
        Offerings
      </Typography>
      <ScopeBanner
        scopeType={contractDetails.contract_scope_type}
        supplierName={contractDetails.supplier.displayName}
        offerings={contractDetails.offerings}
        summary={contractDetails.summary}
        productNames={initialProducts.hits.map((p) => p.itemName)}
        keywords={sortedKeywords}
        query={query}
      />
      <SupplierKeywordsAndProducts
        supplier={contractDetails.supplier}
        products={initialProducts.hits}
        keywords={sortedKeywords}
        query={query}
        showProductList={showProductList}
        handleProductListClick={handleProductListClick}
      />
      <ContractOfferings
        bookmarkProps={{
          onClick: handleDocumentsClick,
          bookmarkType: "contract documents",
        }}
        contractDetails={contractDetails}
      />
      <BookmarkSection
        uniqueBookmarks={uniqueBookmarks}
        handleClickBookmark={handleBookmarkClick}
      />
    </>
  );
}

export function ScopeBanner({
  scopeType,
  supplierName,
  offerings,
  summary,
  productNames,
  keywords,
  query,
}: {
  scopeType: Maybe<ContractScopeTypeEnum>;
  supplierName: string;
  offerings: string[];
  summary: string;
  productNames: string[];
  keywords: string[];
  query: string;
}) {
  const getScopeText = () => {
    if (summary) return summary;

    const exactTextMatch =
      query.length > 1 &&
      [...productNames, ...keywords, ...offerings].some((item) =>
        item.includes(singular(query))
      );

    if (scopeType === ContractScopeTypeEnum.EVERYTHING) {
      return exactTextMatch
        ? `This contract covers all ${supplierName} items, including "${query}".`
        : `This contract covers all ${supplierName} items.`;
    }
    if (scopeType === ContractScopeTypeEnum.ONLY_OFFERINGS && exactTextMatch) {
      return `This contract covers "${query}" and other items from ${supplierName}.`;
    }
  };

  const scopeText = getScopeText();

  if (!scopeText) return null;

  return (
    <div className="flex items-center gap-4 py-2 px-4 rounded-3 bg-cp-cyan-100">
      <img src={VerifiedDocIcon} className="h-6" alt="Verified Document" />
      <ClampedText
        trackShowClamp={() => {
          trackScopeSummaryShowMoreClick({ source: "scopeSummaryBanner" });
        }}
      >
        {scopeText}
      </ClampedText>
    </div>
  );
}

export function SupplierKeywordsAndProducts({
  supplier,
  query,
  keywords,
  products,
  handleProductListClick,
  showProductList,
}: {
  supplier: BaseSupplier;
  query: string;
  keywords: string[];
  products: ProductService[];
  handleProductListClick: () => void;
  showProductList?: boolean;
}) {
  if (!keywords.length && !products.length) return null;
  const ref = useRef<HTMLDivElement>(null);
  const queryMatchesSupplier = matchesSupplier(
    query,
    supplier.handle,
    supplier.displayName
  );
  return (
    <div ref={ref} className="flex flex-col gap-4">
      <ScopeSectionHeader
        title={`Sold by ${supplier.displayName}`}
        bookmarkProps={
          products.length
            ? {
                onClick: handleProductListClick,
                bookmarkType: "supplier products",
              }
            : undefined
        }
      />
      <ChipList
        parentRef={ref}
        keywords={keywords}
        lines={2}
        query={query}
        trackShowMore={() =>
          trackScopeSummaryShowMoreClick({ source: "supplierKeywords" })
        }
      />
      {showProductList && !queryMatchesSupplier && (
        <div className="flex gap-2 flex-wrap">
          {products.slice(0, 5).map(({ id, itemName, variants }) => (
            <ProductCardWithTooltip
              key={id}
              id={id}
              itemName={itemName}
              variants={variants}
              query={query}
              tooltip={itemName}
              onClick={handleProductListClick}
            />
          ))}
        </div>
      )}
    </div>
  );
}

const ProductCardWithTooltip = withTooltip(ProductCard, {
  placement: "bottom",
  className: "max-w-[22rem]",
  fitContent: false,
});

function ProductCard({
  id,
  itemName,
  variants,
  query,
  onClick,
}: {
  id: string;
  itemName: string;
  variants: Array<ProductServiceVariant>;
  query: string;
  onClick: () => void;
}) {
  const firstVariantUrl = variants?.[0]?.photos[0]?.url;
  return (
    <div
      key={id}
      onClick={() => {
        trackScopeSummaryProductCardClick({ itemName });
        onClick();
      }}
      className={clsx(
        "w-[8rem] flex flex-col p-2 gap-2 rounded-3 border border-1 cursor-pointer hover:shadow-1",
        borderColorClass.neutral.default
      )}
    >
      <ImageWithFallback
        className="object-cover"
        src={firstVariantUrl ? firstVariantUrl : imageIcon}
        alt={`image for ${itemName}`}
        title={itemName}
        srcFallback={imageIcon}
        border={false}
      />
      <BoldedText
        size="sm"
        className="line-clamp-2"
        highlight={query}
        text={itemName}
        highlightColor={bgColorClass.accent.persimmon.enabled}
      />
    </div>
  );
}

function BookmarkSection({
  uniqueBookmarks,
  handleClickBookmark,
}: {
  uniqueBookmarks: FileBookmark[];
  handleClickBookmark: (fileBookmark: FileBookmark) => void;
}) {
  if (uniqueBookmarks.length === 0) return null;
  return (
    <div className="flex flex-col gap-4">
      <ScopeSectionHeader title="Contract document highlights" />
      <div className="flex gap-3">
        {uniqueBookmarks.map((b) => (
          <ScopeBookmarkLink
            key={b.bookmark.id}
            onClick={() => handleClickBookmark(b)}
            bookmarkType={b.bookmark.bookmark_type}
          />
        ))}
      </div>
    </div>
  );
}

export interface ScopeBookmarkLinkProps {
  onClick: () => void;
  bookmarkType: string;
}

export function ScopeBookmarkLink({
  onClick,
  bookmarkType,
}: ScopeBookmarkLinkProps) {
  return (
    <BookmarkLink
      onClick={() => {
        trackScopeSummaryBookmarkClick({ bookmarkType });
        onClick();
      }}
      label={`Jump to ${bookmarkType.toLowerCase()}`}
      background
      border
    />
  );
}
