import { useRef } from "react";
import type { BaseContract } from "../../generated";
import useRequestID from "../../hooks/useRequestID";
import { CardContainer, CardContainerVariant } from "../../library";
import type { ContractSolicitationDetails } from "../../pages/Contract";
import AnchorRefLinks, {
  type AnchorRefLink,
} from "../../shared/AnchorRefLinks";
import ProductList from "../../shared/ProductList";
import { borderColorClass } from "../../utils/colors";
import { trackAnchorLinkClick } from "../../utils/tracking";
import { AwardedSuppliers } from "./AwardedSuppliers";
import ContractFiles from "./ContractFiles";
import ContractRecommendations from "./ContractRecommendations";
import ContractSummary from "./ContractSummary";
import { ResellerSuppliers } from "./ResellerSuppliers";
import ScopeSummary from "./ScopeSummary";
import SolicitationPageCTA from "./SolicitationPageCTA";
import SolicitationPageHeader from "./SolicitationPageHeader";
import SupplierSummary from "./SupplierSummary";
import type { TrackContractClickWithinSolicitationCarouselFn } from "./types";
import { scrollToSection } from "./utils";

interface ModularSolicitationPageProps {
  buyerLeadAgency: string;
  contractDetails: ContractSolicitationDetails;
  contractId: string;
  cooperativeAffiliation: string;
  cooperativeUsageSummary: string;
  cooperativeUsageUrl: string;
  membershipUrl: string;
  numSuppliers: number;
  onDocumentsClick: () => void;
  onFirstSupplierClick: () => void;
  onOtherSuppliersClick: () => void;
  query: string;
  savedProjectId: Maybe<string>;
  solicitationId: Maybe<string>;
  title: string;
  anchorLinkTrackingParams: AnchorRefLink["trackingParams"];
  documentsTabName: string;
  showProductList: boolean;
  showScopeSummary: boolean;
  sourceKey: string;
  otherContracts: BaseContract[];
  numOtherContracts: number;
  resellerContracts: BaseContract[];
  numResellerContracts: number;
  trackContractClick: TrackContractClickWithinSolicitationCarouselFn;
  getContractPathWithAnalytics: (id: string) => URL;
  pageType: Maybe<string>;
}

export default function ModularSolicitationPage({
  buyerLeadAgency,
  contractDetails,
  contractId,
  cooperativeAffiliation,
  cooperativeUsageSummary,
  cooperativeUsageUrl,
  membershipUrl,
  numSuppliers,
  query,
  savedProjectId,
  solicitationId,
  title,
  anchorLinkTrackingParams,
  documentsTabName,
  showProductList,
  showScopeSummary,
  sourceKey,
  otherContracts,
  numOtherContracts,
  resellerContracts,
  numResellerContracts,
  trackContractClick,
  getContractPathWithAnalytics,
  pageType,
}: ModularSolicitationPageProps) {
  const requestID = useRequestID();
  const scopeRef = useRef<HTMLDivElement>(null);
  const supplierRef = useRef<HTMLDivElement>(null);
  const productsRef = useRef<HTMLDivElement>(null);
  const documentsRef = useRef<HTMLDivElement>(null);
  const awardedSuppliersRef = useRef<HTMLDivElement>(null);
  const bottomViewerRef = useRef<HTMLDivElement>(null);

  const links: AnchorRefLink[] = [
    {
      pageId: contractId,
      name: "Scope",
      ref: scopeRef,
      className: "analytics-contract-scope-summary-link",
      scrollOptions: { behavior: "smooth" },
      trackingParams: anchorLinkTrackingParams,
      requireLogin: false,
      hidden: !showScopeSummary,
    },
    {
      pageId: contractId,
      name: "Supplier",
      ref: supplierRef,
      className: "analytics-contract-supplier-link",
      scrollOptions: { behavior: "smooth" },
      trackingParams: anchorLinkTrackingParams,
      requireLogin: false,
    },
    {
      pageId: contractId,
      name: "Products",
      ref: productsRef,
      className: "analytics-contract-products-link",
      scrollOptions: { behavior: "smooth" },
      trackingParams: anchorLinkTrackingParams,
      requireLogin: false,
      hidden: !showProductList,
    },
    {
      pageId: contractId,
      name: documentsTabName,
      ref: documentsRef,
      className: "analytics-contract-documents-link",
      scrollOptions: { behavior: "smooth" },
      trackingParams: anchorLinkTrackingParams,
      requireLogin: true,
    },
  ];

  function handleFirstSupplierClick() {
    scrollToSection({
      sectionRef: supplierRef,
    });
    trackAnchorLinkClick({
      ...anchorLinkTrackingParams,
      linkName: "Contract summary main supplier",
      linkClassName: "analytics-contract-main-supplier-link",
    });
  }

  function handleOtherSuppliersClick() {
    scrollToSection({ sectionRef: awardedSuppliersRef });
    trackAnchorLinkClick({
      ...anchorLinkTrackingParams,
      linkName: "Contract summary other suppliers",
      linkClassName: "analytics-contract-other-suppliers-link",
    });
  }

  function handleDocumentsClick() {
    scrollToSection({ sectionRef: documentsRef });
    trackAnchorLinkClick({
      ...anchorLinkTrackingParams,
      linkName: "Contract summary documents",
      linkClassName: "analytics-contract-summary-documents-link",
    });
  }

  function handleProductListClick() {
    if (!showProductList) return;
    scrollToSection({ sectionRef: productsRef });
    trackAnchorLinkClick({
      ...anchorLinkTrackingParams,
      linkName: "Jump to product list",
      linkClassName: "analytics-contract-product-list-link",
    });
  }

  function scrollToViewer() {
    scrollToSection({
      sectionRef: bottomViewerRef,
      options: { behavior: "smooth", block: "end" },
    });
  }

  return (
    <div className="grid gap-y-8">
      <div className="grid grid-cols-4 md:grid-cols-12 gap-x-10 gap-y-8">
        <div className="grid col-start-1 col-span-full lg:col-end-9 gap-8">
          <CardContainer
            variant={CardContainerVariant.SECONDARY}
            className="p-6 rounded-6 h-fit"
          >
            <SolicitationPageHeader
              title={title}
              query={query}
              savedProjectId={savedProjectId}
              contractDetails={contractDetails}
              solicitationId={solicitationId}
            >
              <ContractSummary
                title={title}
                query={query}
                contractDetails={contractDetails}
                cooperativeAffiliation={cooperativeAffiliation}
                cooperativeUsageSummary={cooperativeUsageSummary}
                cooperativeUsageUrl={cooperativeUsageUrl}
                membershipUrl={membershipUrl}
                buyerLeadAgency={buyerLeadAgency}
                solicitationId={solicitationId}
                onFirstSupplierClick={handleFirstSupplierClick}
                onOtherSuppliersClick={handleOtherSuppliersClick}
                onClickDocuments={handleDocumentsClick}
                numSuppliers={numSuppliers}
              />
            </SolicitationPageHeader>
          </CardContainer>
          <div className="lg:hidden max-w-fit">
            <SolicitationPageCTA
              contractDetails={contractDetails}
              pageId={solicitationId}
              requestID={requestID}
            />
          </div>
          <AnchorRefLinks links={links} />
          {showScopeSummary && (
            <CardContainer
              ref={scopeRef}
              variant={CardContainerVariant.SECONDARY}
              className="p-6 rounded-6 h-fit scroll-m-[9.25rem]"
            >
              <ScopeSummary
                contractDetails={contractDetails}
                scrollToViewer={scrollToViewer}
                handleProductListClick={handleProductListClick}
                handleDocumentsClick={handleDocumentsClick}
                showProductList={showProductList}
              />
            </CardContainer>
          )}
          <CardContainer
            variant={CardContainerVariant.SECONDARY}
            className="p-6 rounded-6 h-fit gap-6"
          >
            <SupplierSummary
              ref={supplierRef}
              contractId={contractDetails.contract_id}
              supplier={contractDetails.supplier}
              supplierCompliance={contractDetails.supplierCompliance}
              supplierAgreement={contractDetails.supplierAgreement}
            />
            {showProductList && (
              <>
                <hr className={borderColorClass.neutral.subtle.enabled} />
                <ProductList
                  ref={productsRef}
                  supplierHandle={contractDetails.supplier.handle}
                  supplierName={contractDetails.supplier.displayName}
                  supplierId={contractDetails.supplier.id}
                  contractId={contractDetails.contract_id}
                  contractScope={contractDetails.contract_scope_type}
                />
              </>
            )}
          </CardContainer>
        </div>
        <div className="col-span-4 xl:col-start-9 lg:grid hidden h-fit sticky top-30 z-1">
          <SolicitationPageCTA
            contractDetails={contractDetails}
            pageId={solicitationId}
            requestID={requestID}
          />
        </div>
      </div>
      <CardContainer
        variant={CardContainerVariant.SECONDARY}
        className="p-6 rounded-6 h-fit scroll-m-[5.25rem]"
        ref={documentsRef}
      >
        <ContractFiles
          contractId={contractId}
          solicitationId={solicitationId as string}
          title={title}
          contractDetails={contractDetails}
          buyerLeadAgency={buyerLeadAgency}
          cooperativeAffiliation={cooperativeAffiliation}
          query={query}
          sourceKey={sourceKey}
          requestID={requestID}
          bottomViewerRef={bottomViewerRef}
          scrollToViewer={scrollToViewer}
        />
      </CardContainer>
      {!!numOtherContracts && (
        <CardContainer
          variant={CardContainerVariant.SECONDARY}
          className="p-6 rounded-6 h-fit scroll-m-[9.25rem]"
          ref={awardedSuppliersRef}
        >
          <AwardedSuppliers
            solicitationId={solicitationId}
            contractId={contractId}
            getContractPath={(id) => getContractPathWithAnalytics(id)}
            track={trackContractClick}
            totalNumSuppliers={numOtherContracts}
            initialContracts={otherContracts}
            query={query}
          />
        </CardContainer>
      )}
      {!!numResellerContracts && (
        <CardContainer
          variant={CardContainerVariant.SECONDARY}
          className="p-6 rounded-6 h-fit scroll-m-[9.25rem]"
        >
          <ResellerSuppliers
            totalNumContracts={numResellerContracts}
            solicitationId={solicitationId}
            contractId={contractId}
            getContractPath={(id) => getContractPathWithAnalytics(id)}
            track={trackContractClick}
            count={numResellerContracts}
            initialContracts={resellerContracts}
            displayName={contractDetails.supplier.displayName}
          />
        </CardContainer>
      )}
      <ContractRecommendations
        contractId={contractId}
        contractDetails={contractDetails}
        query={query}
        title={title}
        solicitationId={solicitationId}
        numSuppliersOnSolicitation={numSuppliers + 1}
        buyerLeadAgency={buyerLeadAgency}
        cooperativeAffiliation={cooperativeAffiliation}
        sourceKey={sourceKey}
        trackContractClickWithinSolicitation={trackContractClick}
        pageType={pageType}
      />
    </div>
  );
}
